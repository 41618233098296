import React from 'react';
import './styles/about.css'
import portrait from '../assets/c96a6cbd-3100-4c58-bf14-d041a37a9700.jpg';
import { Link } from 'react-router-dom';
import Verified from '../assets/psychology-today-verified-logo-transparent-hd-png-download.png';

export default function Home() {
  return (
    <section className="about-section general">

      <img src={portrait} alt="" className="me" />

      <p className="aboutIntro">
        <h2>Madison Dean</h2>
        <br />
        (she/her)
        <br />
        Master of Social Work
        <br />
        Registered Social Worker
        <br />
        Clinical Director
        <br />
        <br />
        {/* <img src={Verified} alt="" className="verified" /> */}
      </p>




      <p className="aboutText">
        I am a registered social worker and psychotherapist, and my passion for mental health stems from personal experience. From a young age, I watched my loved ones struggle with their mental health, experiencing the profound impact first-hand. Most significantly, I supported my brother throughout most of his life, as he struggled with severe anxiety, depression, suicidality, and addictions. Naturally, I became curious about how I could help on a larger scale. Through education and experience, I discovered that there are ways to help – ways to create space, understanding, and healing; that we do not have to be stuck or alone.
        <br />
        <br />
        In 2020, my brother tragically died, ultimately because of his lifelong struggle with mental illness. Although I was already a passionate therapist prior to my brother’s death, it has since become my mission to honour him by providing others with the professional support and care that he did not receive - this tragedy has given new meaning to my work. I intimately understand what it means to struggle as a teen and young adult. As someone who has personally struggled with anxiety, trauma, and grief, I also understand what it means to heal.
        <br />
        <br />
        With over 8 years of experience providing services to teens, young adults and families across Durham Region and Toronto, I know that there is no one-size-fits-all approach to therapy. What works for you may not work for someone else, and that's perfectly okay. I believe that you are the expert on yourself. I'm here to collaborate with you, supporting you every step of the way.
        <br />
        <br />
        As a therapist, I'm dedicated to providing you with evidence-based practices, tools, and skills, ensuring you feel confident that you're receiving the support you need. On a personal level, I value connection, compassion, humour, and warmth. While I tailor my approach to each individual, you can always expect me to show up as my authentic self – offering a down-to-earth, genuine atmosphere in our sessions.
        <br />
        <br />
        Having personally faced significant loss and trauma, I deeply believe in the concept that we are always "in bloom." Even when it feels like you're stuck, I see you as ever-growing and resilient. Let me be a source of support as you explore and discover just how resilient you truly are.
      </p>

      <div className="createSesh">
        <Link to="https://inbloomtherapy.janeapp.com/#/staff_member/1" target="_blank">
          Create Appointment
        </Link>
      </div>

      {/* <p className="aboutIntro aboutTitle">
        <h2>About</h2>
      </p>

      <p className="aboutText">
        Hello and welcome! We are so glad you’re here.
        <br />
        <br />
        In Bloom Therapy and Wellness is a mental health therapy practice specializing in working with teens and young adults.          <br />
        <br />
        Our goal at In Bloom is to create a space that is dedicated to young people; a space where teens and young adults can feel heard, validated, safe and empowered; a space where young people can show up comfortably as themselves.          <br />
        <br />
        We are a team of authentic, down-to-earth therapists committed to supporting you in your healing; whatever that may look like for you.
      
      </p> */}

      <p className="aboutIntro">
        <h2>FAQ</h2>
      </p>

      <p className="aboutText">
        <h3>Do you also accept adult clients?</h3>
        <br />
        Absolutely! Although In Bloom specializes in providing therapy to teens and young adults, we also offer individual therapy services to adults of any age. Every member of our team has experience in working with adults as well, and we are happy to provide a free 15 minute consultation so that you can decide if we are the right fit.
        <br />
        <br />
        <h3>Do you accept insurance? </h3>
        <br />
        In Bloom does not offer direct billing to insurance companies; however, most insurers offer coverage for therapy (MSW or RP). We require payment upfront and send a receipt via email that you may submit to your insurance company for reimbursement.
        <br />
        <br />
        <h3>How do I get started? </h3>
        <br />
        Getting started can sometimes be the hardest part. We totally understand, and we are happy to take it step-by-step with you. We offer a free 15 minute consultation if you’d like to chat and decide if we are the right fit for you first. Alternatively, if you’d like to jump right in, you can go ahead and schedule a full first session with one of our therapists. Online scheduling is available for consultations and sessions. If that sounds too overwhelming, please feel free to start by reaching out via telephone or email and we can figure it out together.
      </p>

    </section>
  );
}